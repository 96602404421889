<template>
<div id="wrapper">
    <div id="header">
        <div id="logo" class="ml-3 p-2">
            <img :src="`${publicPath}logo.png`" />
        </div>
        <current-user />
    </div>
    <sidebar />
    <div class="invite-dash mt-1">
        <b-container style="max-width:100%;padding:0px">
            <b-row align-v="center" class="mb-2">
                <b-col sm="12" md="3">
                    <span style="font-weight:300;font-size:36px;">Add a user</span>
                </b-col>
            </b-row>
            <b-row class="mb-4">
                <b-col sm="12" md="9" class="pl-5">
                    <div>
                        <invite-user />
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
    <div id="footer"></div>
</div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue"
import CurrentUser from "../components/CurrentUser.vue"
import InviteUser from "../components/userRegistration/InviteUser.vue"

export default {
  name: 'Invite',
  components: {
      Sidebar,
      CurrentUser,
      InviteUser,
  },
  data(){
      return {
          publicPath: process.env.BASE_URL
      }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
    font-family: sans-serif;
    display: inline;
}
.invite-dash {
    background-color: white;
    padding: 15px;
    border-top-left-radius: 15px;
}
</style>